import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const PortfolioItem = ({ data }) => {
  return (
    <div
      key={data.id}
      className='relative flex w-full flex-col items-center justify-center rounded-md shadow-md'
    >
      <GatsbyImage
        image={data.main_image.childImageSharp.gatsbyImageData}
        className='h-60 w-full rounded-md object-cover'
        alt={data.main_image_alt}
      />
      {/* <span className='flex h-20 items-center p-4 text-center text-lg font-bold text-black'>
        {data.name}
      </span> */}
    </div>
  );
};

export default PortfolioItem;
