import React from 'react';
import { Link } from 'gatsby';
import PortfolioItem from './portfolioItem';

const PortfolioWrapper = ({ data }) => {
  return (
    <>
      <div className='px-4 pt-20'>
        <h1 className='py-4 text-center text-3xl font-bold text-myOrange'>
          {data.allPortfolioJson.edges[0].node.category}
        </h1>
      </div>
      <div className='mx-auto grid min-h-screen max-w-screen-xl grid-cols-1 items-center justify-center gap-4 px-4 pb-10 sm:grid-cols-2 lg:grid-cols-3'>
        {data.allPortfolioJson.edges.map(({ node }) => {
          return (
            <Link to={`${node.id_work}`} id={node.id_work} key={node.id}>
              <PortfolioItem data={node} />
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default PortfolioWrapper;
